<template>
            <div class="container slider">
                <carousel :lazyLoad="false" :autoplay="true" :refreshed="true" :items=1 :nav="true" :navText="['', '']"
                          :loop="true" :dots="true" :center="true" >
                    <img class="slide-img" v-for="slide in home.sliders" :key="slide.id" :src="'https://eurasiasupplies.com/euadmin/'+slide.photo" :alt="'slide.photo_alt'">
                </carousel>
            </div>

</template>
<script>
    import carousel from 'vue-owl-carousel'
    import {mapGetters} from "vuex";

    export default {
        name: 'Slider',
        components: {
            carousel
        },
        props: {
            msg: String
        },
        computed: {
            ...mapGetters(["home"]),
        },
       /*  mounted() {
            this.$store.dispatch("getHome");
        }, */
    }
</script>
<style >

    .slider .owl-carousel .owl-nav{
        position: absolute;
        top: 50% !important;
        margin-top: -65px !important;
        width: 100%;
        display: flex;
        height: 0;
        justify-content: space-between;
        background: transparent !important;
        padding: 0px 35px;
    }

    .slider .owl-carousel .owl-nav .owl-prev, .slider .owl-carousel .owl-nav .owl-next {
        font-size: 100px !important;
        color: #fff !important;
        height: 100px;
        font-weight: normal;
        padding-top: 35px !important;
        width: 35px !important;
        background: transparent !important;
    }
    .slider .owl-carousel .owl-nav .owl-next {
        padding-right: 40px !important;
    }
    .slider .owl-carousel .owl-dots {
        position: absolute;
        bottom: 15px;
        width: 100%;
    }
    .slider .owl-carousel .owl-dots .owl-dot{
        zoom: 1.7 !important;
    }
    .slider .owl-carousel .owl-dots .owl-dot.active span{
        background: #0A0A0A !important;
    }
    .slide-img{
        height: 400px;
    }
    .slider .owl-prev:after {
        position: absolute;
        content: "\F104";
        font-family: 'FontAwesome';
        left: 33px;
        color: #fff;
        font-weight: normal;
        font-size: 1em;
        background-color: transparent;
    }
    .slider .owl-next:after {
        position: absolute;
        content: "\F105";
        font-family: 'FontAwesome';
        right: 45px;
        color: #fff;
        font-weight: normal;
        font-size: 1em;
        background-color: transparent;
        padding-top: 15px;
    }
    @media(min-width:1367px){
        .slider .owl-item {
            height: 713px;
        }
        .slider .slide-img { height: 100%}
    }
    @media(min-width:1025px) and (max-width:1366px){
        .slider .owl-item {
            height: 465.306px;
        }
        .slider .slide-img { height: 100%}
    }
    @media(min-width:1025px) and (max-width:1366px){
        .slider .owl-item {
            height: 465.306px;
        }
        .slider .slide-img { height: 100%}
    }
    /*@media(min-width:768px) and (max-width:1024px){*/
    /*    .slider .owl-item {*/
    /*        height: 300px;*/
    /*    }*/
    /*    .slider .slide-img { height: 100%}*/
    /*    .slider .owl-carousel .owl-nav .owl-prev, .slider .owl-carousel .owl-nav .owl-next {*/
    /*        font-size: 50px !important;*/
    /*    }*/
    /*}*/
    @media(min-width:300px) and (max-width:767px){
        .slider .owl-item {
            height: 150px;
        }
        .slider .slide-img { height: 100%}
        .slider .owl-carousel .owl-nav .owl-prev, .slider .owl-carousel .owl-nav .owl-next {
            font-size: 50px !important;
        }
        .slider .owl-carousel .owl-nav {
            width: 100%;
            padding: 0px 0px !important;
        }
        .slider .owl-carousel .owl-nav .owl-next {
            padding-right: 7px !important;
        }
        .owl-theme .owl-dots .owl-dot span {
            width: 5px;
            height: 5px;
            margin: 0px 2px;
        }
        .slider .owl-next:after {
            right: 12px;
            font-size: 0.6em;
        }
        .slider .owl-prev:after {
            left: 14px;
            font-size: 0.6em;
        }
    }
</style>