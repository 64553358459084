<template>
  <div class="home">
    <img v-if="!isSliderLoad" :src="'/eurasia/img/logo/loading.gif'" style="width: 100px; height:80px" alt="logo">
    <div v-if="isSliderLoad" class="main_slider">
      <Slider />
    </div>
    <OfferArea />
    <!--<OfferProduct/>-->
    <div v-if="isSliderLoad" class="">
      <SalesNow></SalesNow>
      <HomeDynamicSection></HomeDynamicSection>
      <LatestProduct/>
      <TopSellingProduct/>
    </div>


  </div>
</template>

<script>
// @ is an alias to /src
import OfferArea from "../components/Home/OfferArea";
import SalesNow from "../components/Home/SalesNow";
import HomeDynamicSection from "../components/Home/HomeDynamicSection";
import LatestProduct from "../components/Home/LatestProduct";
import TopSellingProduct from "../components/Home/TopSellingProduct";
import Slider from "./header/Slider";
import { mapGetters } from "vuex";
import carousel from 'vue-owl-carousel'
export default {
  name: 'Home',
  data() {
    return {
      isSliderLoad: false
    };
  },
  metaInfo() {
    return {
      title: "Eurasia Supplies Brand Cosmetics of bangladesh",
      titleTemplate: "Eurasia Supplies Brand Cosmetics of bangladesh",
      meta: [
        /*{
            name: "description",
            content: this.productDetails.meta_description,
        },
        {
            name: "keyword",
            content: this.productDetails.meta_keyword,
        },*/
      ],
    };
  },
  async mounted() {
    // await this.$store.dispatch("getHome");
    // await this.$store.dispatch("getTopSelling");
    // await this.$store.dispatch("clearSiteUrl");
    // await this.$store.dispatch("getsaleNow");
    // console.log("Hello Home")
    setTimeout(function () {
      this.isSliderLoad = true
    }.bind(this), 500)
  },
  computed: {
    ...mapGetters(["loading", "home"]),
  },
  components: {
    OfferArea,
    SalesNow,
    HomeDynamicSection,
    LatestProduct,
    TopSellingProduct,
    Slider,
    carousel
  }
}
</script>
