<template>
  <section class="product_section sale_now">
    <div class="">
      <div class="slider-titile">
        <br>
        <h4 class="text-center">Sales Now</h4>
      </div>
      <SaleNowProduct :products="saleNow" />
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
//import SaleNowProduct from "@/components/Home/SingleProductItem.vue"
import SaleNowProduct from "../Home/SingleProductItem"
export default {
  components: {
    SaleNowProduct
  },
  computed: {
    ...mapGetters(["saleNow"]),
  },
};
</script>

<style scoped>

</style>
